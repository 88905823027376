<template>
  <v-app class="ma-3" elevation="1">
    <v-skeleton-loader :elevation="2" type="card-avatar" v-if="loading"></v-skeleton-loader>
    <v-main v-else>
      <v-container fluid grid-list-md>
        <v-row dense>
          <v-col sm="12" md="3" v-for="count in counts" :key="count['flag']">
            <v-card elevation="5" @click="$router.push({ name: 'FlagDetails', params: { id : 1, flag: count['id']} })" rounded class="pa-1 ma-3 d-flex justify-space-arround align-center" color="teal"
              outlined>
              <v-card-text>
                <v-icon :color="count['flag']">mdi-flag</v-icon>
                Total {{ (count['flag'] == "indigo") ? "PAC" : "Drapeau" }} {{ translateColor(count['flag']) }} : {{ count['count'] }}
              </v-card-text>
              <v-icon size="x-large" end>mdi-chart-pie</v-icon>
            </v-card>
          </v-col>
          <v-col md="12">
            <v-row>
              <department-compo v-for="department in departments" :key="department.code" :department="department" :code="department.code" :counts2="counts2" :perCreated="0" :archived="archived" ></department-compo>
            </v-row>
          </v-col>
          <!-- <v-col md="4">
            <v-date-picker elevation="10" color="teal" show-adjacent-months></v-date-picker>
          </v-col> -->
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script setup>
import { translateColor } from "@/utils";

</script>
<script>
import axios from "axios";
import DepartmentCompo from "@/components/DepartmentCompo.vue";
import { getRandomColor } from "@/utils";

export default {
  components: { DepartmentCompo },
  props: ["code"],
  name: "App",
  component() {
    DepartmentCompo;
  },
  data: () => ({
    departments: [],
    counts: [],
    counts2: [],
    loading: true,
    archived : false
  }),
  methods: {

  },
  mounted() {
    this.$store.commit("setId", this.$route.params.id);

    this.archived = this.$route.params.archived
    
    axios.get("https://roqserver.sophal.net/api/fetchUser/" + this.$route.params.id).then((response) => {
      let user = response["data"];
      this.$store.commit("setUser", user);
      axios.get("https://roqserver.sophal.net/api/departments").then((response) => {
        // if (this.$route.params.id == 1 || this.$route.params.id == 242 || this.$route.params.id == 3) {
          this.departments = response["data"];
        // } else {
        //   this.departments = response["data"].filter(e => e.id == user.department.id)
        // }
        this.loading = false
      });
    });

    axios.get("https://roqserver.sophal.net/api/events/group").then((response) => {
      response["data"].forEach(element => {
        this.counts.push({
          "id" : element[0],
          "flag": getRandomColor(element[0]),
          "count": element[1]
        });

      });

    });
    axios.get("https://roqserver.sophal.net/api/events/group/dep").then((response) => {
      response["data"].forEach(element => {
        this.counts2.push({
          "flag": getRandomColor(element[0]),
          "DIR": element[1],
          "count": element[2]
        });
      });
    })
  },
};
</script>
