<template>
  <v-col md="3">
    <v-card :title="department.code" :subtitle="department.code" density="comfortable" color="teal-darken-4"
      class="rounded-lg" flat>
      <v-card-text>
        <div v-for="counter in filtedCounter(counts2, department.code)" :key="counter.flag">
          <v-icon :color="counter.flag">mdi-flag</v-icon> : {{ counter.count }}
        </div>
        <!-- <v-icon color="red">mdi-flag</v-icon>
        <v-icon color="orange">mdi-flag</v-icon> -->
      </v-card-text>
      <v-card-actions color="white">
        <v-btn color="white" bg-color="white" size="x-large"
          @click="$router.push({ name: 'DepartmentEvent', params: { departmentId: department.id, id: $route.params.id, code: department.code, perCreated: perCreated, archived : archived } })">
          Details <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
// let self = this

export default {
  name: "DepartmentCompo",
  props: ["department", "counts2", "perCreated", "code","archived"],
  methods: {

    filtedCounter(counters, department) {

      var depCounters = counters.filter(e => e.DIR == department);
      var flags = ["grey", "red", "orange", "green"]


      flags.forEach(flag => {
        if (!depCounters.map(e => e.flag).includes(flag)) {
          depCounters.push({ flag: flag, DIR: department, count: 0 })
        }
      });

      return depCounters
    }
  },
  mounted() {

  },
};
</script>