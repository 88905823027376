import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vueKanban from 'vue-kanban'
import { createI18n } from 'vue-i18n'
import ganttastic from '@infectoone/vue-ganttastic'

const i18n = createI18n({
  locale: 'fr',
  fallbackLocale: 'en',
  messages: {
    en: {
      message: {
        hello: 'hello world'
      }
    },
    fr: {
      message: {
        hello: 'Bonjour'
      }
    }
  }
})

loadFonts()


router.beforeEach(async (to, from, next) => {
  store.commit("setId",to.params.id);
  next();
});

createApp(App).use(store).use(store).use(router)
  .use(vuetify).use(VueSweetalert2).use(vueKanban)
  .use(i18n)
  .use(ganttastic)
  .mount('#app')
