var getRandomColor = (flag) => {
    var color = '#';
    switch (flag) {
        case 0:
            color = "grey";
            break;
        case 1:
            color = "red";
            break;
        case 2:
            color = "orange";
            break;
        case 3:
            color = "green";
            break;
        case 4:
            color = "indigo";
            break;
        default:
            color = "#ff0000";
            break;
    }

    return color;
}

var setRandomColor = (flag) => {
    var color = '#';
    switch (flag) {
        case "grey":
            color = 0;
            break;
        case "red":
            color = 1;
            break;
        case "orange":
            color = 2;
            break;
        case "green":
            color = 3;
            break;
        case "indigo":
            color = 4;
            break;
        default:
            color = 0;
            break;
    }

    return color;
}


var translateColor = (flag) => {
    var translation = '';
    switch (flag) {
        case "grey":
            translation = "Gris";
            break;
        case "red":
            translation = "Rouge";
            break;
        case "orange":
            translation = "Orange";
            break;
        case "green":
            translation = "Vert";
            break;
        default:
            translation = "";
            break;
    }

    return translation;
}

var flags = [{
        id: 0,
        name: 'Budget',
        color: 'grey',
    },
    {
        id: 1,
        name: 'Rouge',
        color: 'red',
    },
    {
        id: 2,
        name: 'Orange',
        color: 'orange',
    },
    {
        id: 3,
        name: 'Vert',
        color: 'green',
    },
    {
        id: 4,
        name: 'Pac',
        color: 'indigo',
    },
];


export {
    getRandomColor,
    setRandomColor,
    translateColor,
    flags
}