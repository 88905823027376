<template>
    <v-sheet :color="action.color" elevation="4" border="5" rounded outline>
      <!-- <v-card-title primary-title class="text-white">
        {{ action.title.toUpperCase() }}
      </v-card-title> -->
      <v-divider></v-divider>
      <v-card-text color="white">
        <h3 class="text-white">
          {{ action.title.toUpperCase() }}
          <!-- */*/*/*/*/ {{ action.createdBy.department.id }} */*/*/*/*/*
          */*/*/*/*/ {{ this.$store.state.user.department.id  }} */*/*/*/*/* -->
        </h3>
        <!-- {{ action.description }} -->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="right text-white pa-2">
        <h4>
          ** <span v-for="(dep,index) in action.departments" :key="dep.code">{{ dep.code.toUpperCase()}} {{  ( index < action.departments.length -1  ) ? "; " : ""  }} </span> **
        </h4>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions style="background : white" class="d-block" >
        <div class="pa-1">
          <span :class="`text-${action.color}`" style="font-size: 12px;"><b>CREE LE :</b> {{ action.createdOn }}</span>
          <br>
          <span :class="`text-${action.color}`">--------------------------------</span>
          <br>
          <span>
            <span :class="`text-${action.color}`"><b>CREE PAR :</b> {{ action.departmentEmitter?.code }}</span>
          </span>
          <br>
          <span :class="`text-${action.color}`">--------------------------------</span>
        </div>
        <div class="d-flex justify-space-between align-center">
          <v-icon :color="action.color">mdi-flag</v-icon>
          <div>  
            <v-btn :color="action.color" icon="mdi-information-outine" density="compact" variant="tonal" @click="updateEvent(action.id,true,true)" >
              <v-icon size="small">mdi mdi-information</v-icon>
            </v-btn>
            <v-btn :color="action.color" icon="mdi-plus" density="compact" variant="tonal" @click="updateEvent(action.id,true,false)"  v-show="this.$route.params.id == 330 || this.$route.params.id == 1 || this.$route.params.id == 242 || this.$route.params.id == 3 || (this.$store.state.user.department.id == this.$route.params.departmentId) || (this.$store.state.user.id == 181 && this.$route.params.departmentId == 6)">
              <v-icon size="small">mdi mdi-pencil</v-icon>
            </v-btn>
            <v-btn :color="action.color" icon="mdi-delete" density="compact" variant="tonal" @click="deleteEvent(action.id)"  v-show="this.$route.params.id == 330 || this.$route.params.id == 1 || this.$route.params.id == 242  || this.$route.params.id == 3">
              <v-icon size="small">mdi mdi-delete</v-icon>
            </v-btn>
          </div>
      </div>
      </v-card-actions>
    </v-sheet>

</template>

<script>
export default {
  props: ["action"],
  inject: ['saveOrUpdate2','deleteEvent2'],
  methods :  {
    updateEvent(id,dialog,readOnly) {
      console.log("event cccc******",id,dialog,readOnly);
      this.saveOrUpdate2(id,dialog,readOnly);
    },
    deleteEvent(id) {
      this.deleteEvent2(id);
    }
  }
}
</script>