<template>
    <v-app class="ma-3" elevation="1" :style="{ backgroundColor: currentColor }">
        <v-skeleton-loader :elevation="2" type="card-avatar" v-if="loading"></v-skeleton-loader>
        <v-main v-else>

            <v-card class="ma-3">
                <v-row>
                    <v-col cols="4" md="4" sm="6">
                        <v-text-field type="datetime-local" label="Date debut" v-model="startDate"></v-text-field>
                    </v-col>
                    <v-col cols="4" md="4" sm="6">
                        <v-text-field type="datetime-local" label="Date fin" v-model="endDate"></v-text-field>
                    </v-col>
                    <v-col cols="4" md="4" sm="6">
                        <v-select :items="precisions" item-title="Precision" label="Precision" item-value="id"
                            v-model="precision" persistent-hint>
                            <!-- <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :subtitle="item.raw.code">
              </v-list-item>
            </template> -->
                        </v-select>
                    </v-col>
                </v-row>
            </v-card>
            <g-gantt-chart :chart-start="startDate.replace(/T/, ' ')" :chart-end="endDate.replace(/T/, ' ')"
                :precision="precision" bar-start="myBeginDate" bar-end="myEndDate" width="100%" grid="false"
                noOverlap="true" pushOnOverlap="false" color-scheme="vue">
                <g-gantt-row :label="row1Bar['departmentEmitter']" :bars="[row1Bar]"
                    @click="saveOrUpdate(row1Bar['ganttBarConfig']['id'], dialog, false)"
                    v-for="row1Bar in row1BarList.sort((a, b) => a['departmentEmitter']['id'] > b['departmentEmitter']['id'])"
                    :key="row1Bar" highlight-on-hover="true">
                </g-gantt-row>
                <!-- <g-gantt-row label="Orange" :bars="row2BarList" />
                <g-gantt-row label="Green" :bars="row2BarList" /> -->
            </g-gantt-chart>
        </v-main>



        <v-dialog v-model="dialog" width="600">
            <v-card max-width="800" prepend-icon="mdi-plus" text="Priere de saisir ....." title="Information...">
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12" md="12" sm="6">
                            <v-text-field label="Titre" required v-model="event.title"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-textarea label="Description" v-model="event.description" readonly="true"></v-textarea>

                    <v-row>
                        <v-col cols="6" md="6" sm="6">
                            <v-text-field type="datetime-local" label="Date debut"
                                v-model="event.startDate"></v-text-field>
                        </v-col>
                        <v-col cols="6" md="6" sm="6">
                            <v-text-field type="datetime-local" label="Date fin" v-model="event.endDate"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-select :items="departments" clearable item-title="code" label="Direction Emettrice"
                        item-value="id" v-model="event.departmentEmitter" persistent-hint>
                        <!-- <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :subtitle="item.raw.code">
              </v-list-item>
            </template> -->
                    </v-select>

                    <v-select :items="departments" clearable chips item-title="code" label="Direction" item-value="id"
                        v-model="event.departments" multiple persistent-hint>
                        <!-- <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :subtitle="item.raw.code">
              </v-list-item>
            </template> -->
                    </v-select>

                    <v-select :items="flags" item-title="name" label="Drapeau" item-value="id" v-model="event.flag">
                        <template v-slot:item="{ props, item }">
                            <v-list-item v-bind="props" :subtitle="item.raw.code" append-icon="mdi-flag"
                                :base-color="item.raw.color">
                            </v-list-item>
                        </template>
                    </v-select>

                    <!-- <small class="text-caption text-medium-emphasis">*indicates required field</small> -->

                </v-card-text>
            </v-card>
        </v-dialog>

    </v-app>

</template>



<script>
import axios from "axios";
import { getRandomColor,flags } from "@/utils";
import moment from "moment";

export default {
    name: "FlagDetails",
    data: () => ({
        event: {

        },
        row1BarList: [],
        loading: true,
        startDate: moment().subtract(6, 'months').format().replace(/T/, ' ').substring(0, 16),
        endDate: moment().format().replace(/T/, ' ').substring(0, 16),
        precision: "month",
        precisions: ["hour", "day", "month", "week", "date"],
        currentColor: "",
        dialog: false,
        departments: [],
        flags : flags
    }),
    methods: {
        saveOrUpdate(id, dialog, readOnly) {

            this.editMode = true
            this.readOnly = readOnly
            var self = this
            axios.get("https://roqserver.sophal.net/api/events/" + id).then((response) => {
                var element = response["data"];
                self.event.id = element.id
                self.event.title = element.subject
                self.event.description = element.description
                self.event.startDate = element.startDateTime
                self.event.endDate = element.endDateTime
                self.event.departmentEmitter = element.departmentEmitter
                self.event.departments = element.departments.map(e => e.id)
                self.event.flag = element.flag
                // self.editMode = true;
                self.dialog = !dialog

            }).catch(err => {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Erreur!",
                    footer: err
                });
            });
        },
    },
    mounted() {

        this.currentColor = getRandomColor(this.$route.params.flag - 0);


        axios.get("https://roqserver.sophal.net/api/departments").then((response) => {
            response["data"].forEach(element => {
                this.departments.push({
                    id: element["id"],
                    code: element["code"],
                    name: element["name"],
                })
            });
        });

        axios.get("https://roqserver.sophal.net/api/flagDetails/" + this.$route.params.flag).then((response) => {
            let events = response["data"].sort((a, b) => a["departmentEmitter"]["id"] > b["departmentEmitter"]["id"]);

            events.forEach(element => {
                if (element != null) {

                    this.row1BarList.push({
                        myBeginDate: element["startDateTime"],
                        myEndDate: element["endDateTime"],
                        departmentEmitter: element["departmentEmitter"]["code"],
                        ganttBarConfig: {
                            // each bar must have a nested ganttBarConfig object ...
                            id: element["id"], // ... and a unique "id" property
                            label: element["subject"],
                            hasHandles: true,

                            style: {
                                // arbitrary CSS styling for your bar
                                background: getRandomColor(element['flag']),
                                borderRadius: "20px",
                                color: "white"
                            }
                        }
                    })
                    this.loading = false
                }

            });
        });

    },
};
</script>
