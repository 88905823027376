import {
  createRouter,
  createWebHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from "../views/AboutView.vue"
import DepartmentEvent from '../views/DepartmentEventView.vue';
import MeetingView from '../views/MeetingView.vue'
import MyEvent from '../views/MyEvent.vue'
import EventsPerDepCre from '../views/EventsPerDepCre.vue'
import Grid from '@/views/Grid.vue';
import FlagDetails from '@/components/FlagDetails.vue';
import Store from "../store";
import axios from 'axios';

const routes = [{
    path: '/:id?',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:id?/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/:id?/eventsPerDepCre',
    name: 'eventsPerDepCre',
    component: EventsPerDepCre
  },
  {
    path: '/:id?/DepartmentEvent/:departmentId?/:code?/:perCreated?/:archived?',
    name: 'DepartmentEvent',
    component: DepartmentEvent,
    props: true
  },
  {
    path: '/:id?/archived/:archived?',
    name: 'archived',
    component: HomeView,
    props: true
  },
  {
    path: '/:id?/meeting',
    name: 'meeting',
    component: MeetingView
  },
  {
    path: '/:id?/myevents',
    name: 'myevents',
    component: MyEvent
  },
  {
    path: '/:id?/grid',
    name: 'grid',
    component: Grid
  },
  {
    path: '/:id?/flag-details/:flag',
    name: 'FlagDetails',
    component: FlagDetails
  },
]

const router = createRouter({
  history: createWebHistory(),
  hash: false,
  routes
})

router.beforeEach((to, from) => {
  // ...
  // explicitly return false to cancel the navigation

  axios.get("https://roqserver.sophal.net/api/fetchUser/" + to.params.id).then((response) => {
    Store.commit("setUser", response["data"]);
  });

  console.log("from", from);
  console.log("to", to);
  Store.commit("setId", to.params.id);
})

export default router